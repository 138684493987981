.embed-map #embed-zoom .zoom-ctrl {
  font-size: 16px;
  line-height: 1.6;
  width: 25px;
  height: 25px;
  /* border-radius: 25px; */
  background-color: red;
  margin-bottom: 4px;
  text-align: center;
  align-self: flex-end;
}
.windy-container{
  height: 100%;
}
.windy-wrapper {
  /* max-width: 1200px; */
  margin: auto;
  /* display: flex; */
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
  /* top: 100px; */
  margin-top: 20px;
}
.windy-location {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.windy-location-box-wrap {
  background-color: white;
  box-shadow: rgba(182, 182, 182, 0.16) 0px 15px 30px;
  border-radius: 4px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.windy-left-arrow-wrap {
  background-color: rgba(247, 108, 108, 0.07);
  padding: 15px;
  cursor: pointer;
}
.left-arrow-img {
  width: 20px;
}

.windy-loc_round {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  color: white;
  font-family: "Source-Bold";
  font-size: 0.8em;
  background-color: rgb(247, 108, 108);
  margin-left: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.windy-loc_name {
  margin: 0px 0px 0px 4px;
  text-align: center;
  color: rgb(5, 25, 47);
  font-size: 1em;
  font-family: "Source-Semibold";
}
.surferBtn-align {
  position: absolute;
  top: 110px;
  right: 140px;
}
.surfer-Button {
  align-items: center;
  text-align: center;
  cursor: pointer;
  padding: 7px;
  width: 89px;
  display: flex;
  background-color: white;
  justify-content: center;
  border-radius: 3px;
}
.surfer-Button:hover {
  background: #b6b6b690;
}
.surfer-text {
  color: #05192f;
  font-family: Source-SemiBold;
  font-size: 1em;
}
.fav-icon {
  width: 20px;
  position: relative;
  right: 8px;
}
.windy-dropdown-items {
  position: absolute;
  top: 45px;
  right: 2px;
  width: 159px;
  z-index: 2;
  background-color: white;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.windy-page-container {
  margin: 0 auto;
}
.items {
  padding: 8px 12px;
  color: #05192f;
  font-family: "Source-Reg";
}

.items:hover {
  background-color: rgba(0, 0, 0, 0.14);
  cursor: pointer;
}
.windy-height-align {
  position: relative;
}
@media (max-width: 575.98px) {
  .windy-page-container {
    max-width: 100%;
  }
  .windy-wrapper {
    width: 100%;
    /* top: 120px; */
  }
  .windy-location {
    margin-left: 2%;
    /* width:100%; */
  }
  /* .windy-wrapper {
    top: 110px;
  } */

  .surfer-Button {
    position: relative;
    top: 86px;
    right: 10px;
  }
  .dropdown-items {
    width: 133px;
  }
  .windy-height-align {
    height: calc(100% - 77px);
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .windy-page-container {
    max-width: 540px;
  }
  .windy-wrapper {
    width: 100%;
    /* top: 120px; */
  }
  .windy-height-align {
    height: 82.5vh;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .windy-page-container {
    max-width: 720px;
  }
  .windy-wrapper {
    width: 100%;
  }
  .windy-height-align {
    height: 87.4vh;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .windy-page-container {
    max-width: 960px;
  }
  .windy-wrapper {
    width: 100%;
  }
  .windy-height-align {
    height: 86vh;
  }
}
@media (min-width: 1200px) and (max-width: 1367.98px) {
  .windy-page-container {
    max-width: 1140px;
  }
  .windy-wrapper {
    width: 100%;
  }
  .windy-height-align {
    height: 85vh;
  }
}
@media (min-width: 1368px) and (max-width: 1919.98px) {
  .windy-page-container {
    max-width: 1200px;
  }
  .windy-wrapper {
    width: 100%;
  }
  .windy-height-align {
    height: 89.8vh;
  }
}
@media (min-width: 1920px) {
  .windy-page-container {
    max-width: 1700px;
  }
  .windy-wrapper {
    width: 100%;
  }
  .windy-height-align {
    height: 89.8vh;
  }
}
