/* .imageBackground {
    background-image: url("../../assets/images/background.png"),url("../../assets/images/whiteBackground.svg");
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
} */

.imageBackground {
  /* background-image: url("../../assets/images/Background.png"),
  url("../../assets/images/whiteBackground.svg");
  height: 100vh;
  background-position:center center;
  background-repeat: no-repeat, no-repeat; */
  /* background-size: cover; */
  background-image: url("../../assets/images/whiteBackground.svg"),
    url("../../assets/images/whiteBackground.svg");
  /* height: 100vh; */
  background-position: bottom 0px right 0px, 76% 22%;
  /* background-size: cover; */
  /* background-position:bottom 0px right 0px,right 50% ; */
  /* background-position: bottom 0px right 0px, 69% 20%;; */
  background-repeat: no-repeat, no-repeat;
  /* background-image: 
    url("../../assets/images/Background.png"),url("../../assets/images/whiteBackground.svg");
  height: 100vh;
  background-position:59% 27%, right 35% bottom 45%;;
  background-repeat: no-repeat, no-repeat; */
  /* background-position: left;
  background-repeat: no-repeat;
  background-size:100% ;
  position: relative; */
  /* background-position: right bottom, left top; 
  background-repeat: no-repeat, repeat;
   background-position:center, center;   */
  /* background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../../assets/images/background.png"); */
  /* background-size: cover;
    background-position:left, right; 
    background-repeat: no-repeat, no-repeat; 

     */
  /* background-position: right center, center center; */
  /* background-position: left center, right;
  background-repeat: no-repeat;
  background-size: auto auto, cover; */
  /* background-image: url("../../assets/images/background.png");
    background-position: right;
    background-size: 100%;
    
    background-repeat: no-repeat;
    position: relative; */
}
.logoImg {
  height: 60px;
  width: 300px;
}

.overlay {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
  background: linear-gradient(45deg, transparent, white);
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
  /* background-color: red; */
}

.background {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
}

.login-text {
  font-size: 2em;
  color: #f76c6c;
  margin: 0;
  font-family: "Source-Bold";
}

.sub-text {
  color: #00000050;
  font-size: 1em;
  margin: 0;
  margin-top: 2%;
  margin-bottom: 10%;
  font-family: "Source-Reg";
}

.left-side-text {
  font-size: 1.2em;
  color: #000000;
  margin: 0;
  margin-bottom: 4px;
  
}

.main {
  justify-content: space-between;
  display: flex;
  flex: 1px;
  /* margin: 10%; */
}

.left-side,
.right-side {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  /* z-index: 9; */
}

.right-side {
  margin-left: auto;
  max-width: 342px;
}

.left-side {
  margin-right: auto;
  max-width: 443px;
}

.img {
  height: 80px;
  min-width: 200px;
}

.input-container {
  /* width: 85%; 
  display: flex;
  align-items: center;
  border: 1px solid #2680eb0f;
  border-radius: 3px;
  background-color: #2680eb0f;
  margin-bottom: 5%;
  padding: 10px; */
  width: 100%;
  display: flex;
  align-items: center;
  /* border: 1px solid #CDDEF3; */
  border-radius: 3px;
  background-color: #cddef3;
  /* padding: 10px; */
}

.emailInput {
  margin-bottom: 5%;
}

.input-container input {
  /* padding:14px; */
  /* width: 100%;
  font-size: 14px;
  border: 0;
  outline: none;
  color: black;
  background-color: #2680eb0f;
  margin-left: 10px; */
  padding: 16px 16px 16px 0px;
  width: 100%;
  font-size: 0.9em;
  /* font-size: 14px; */
  border: 0;
  outline: none;
  color: black;
  background-color: #cddef3;
  /* margin-left: 10px; */
}

.input-container input::placeholder {
  color: #05192f;
  font-size: 1em;
  font-family: "Source-SemiBold";
  /* opaci/ty: 1; */
}

.icons {
  /* margin: 0 10px; */
  color: #aaa;
  margin-left: 10px;
  margin-right: 10px;
}

.eye-icon {
  margin-right: 10px;
  cursor: pointer;
}

.error-Text {
  font-size: 0.8em;
  color: red;
  margin: 0;
  margin-top: 10px;
}

.forgot {
  align-self: flex-end;
  position: relative;
  /* right: 30px; */
  margin-bottom: 10%;
  color: #00000050;
  font-size: 0.9em;
  font-family: "Source-Reg";
  cursor: pointer;
}

.button-container {
  width: 85%;
  /* margin:50px 10px; */
  display: flex;
  align-items: center;
  border: 1px solid #f76c6c;
  border-radius: 3px;
  background-color: #f76c6c;
  margin-bottom: 5%;
  padding: 11px;
  cursor: pointer;
}

.button {
  width: 100%;
  font-size: 1.1em;
  border: 0;
  cursor: pointer;
  outline: none;
  color: #ffffff94;
  background-color: #f76c6c;
  margin-left: 10px;
  font-family: Source Sans Pro, sans-serif;
}

.button-container:hover {
  -moz-box-shadow: 0 0 5px #999;
  -webkit-box-shadow: 0 0 5px #999;
  box-shadow: 0 0 5px #999;
  /* box-shadow: 0px 0px 2px 2px #fff;  */
}

.footerText {
  color: #05192f94;
  font-size: 1em;
  margin: 0;
  font-family: "Source-SemiBold";
}

.btn {
  border: 2px solid red;
  border-radius: 3px;
  background-color: #f76c6c;
  color: white;
  padding: 15px 20px;
  border: none;
  margin-bottom: 5%;
  cursor: pointer;
  font-family: "Source-SemiBold";
  font-size: 0.9em;
  width: 100%;
  opacity: 0.9;
  outline-color: red;
}

.btn:hover {
  opacity: 1;
  -moz-box-shadow: 0 0 5px #999;
  -webkit-box-shadow: 0 0 5px #999;
  box-shadow: 0 0 5px #999;
}

.sweet-loading {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #000000aa;
  display: flex;
  justify-content: center;
  align-items: center;
}
.left-arrow-back-to-home {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  font-family: "Source-SemiBold";
  color: #f76c6c;
  cursor: pointer;
}
.footer-left-arrow {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  font-family: "Source-SemiBold";
  color: rgba(55, 71, 133, 0.58);

  cursor: pointer;
}
.login-left-arrow-icon {
  position: relative;
  font-size: 30px;
  top: -5px;
  color: #f76c6c;
}
.password-indicator-items:nth-child(5) {
  margin-bottom: 20px;
}
/* responsive */
@media (max-width: 575.98px) {
  .main {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    height: 730px;
    /* margin:2%; */
  }
  .left-side {
    margin: 0 auto;
    max-width: 443px;
    margin-top: 10px;
  }
  .right-side {
    margin: 0 auto;
    max-width: 443px;
    margin-top: -260px;
  }

  .right-side-text {
    margin: 0 auto;
    max-width: 443px;
    margin-top: -160px;
  }

  .left-side-text {
    font-size: 1.1em;
    margin-top: -30px;
    margin-bottom: 50px;

  }
  .login-text {
    font-size: 1.5em;
  }
  .imageBackground {
    background-position: bottom 0px right 0px, 88% 11%;
  }
  .left-side,
  .right-side {
    width: 68%;
  }
  .footerText {
    font-size: 0.92em;
  }
  .forgot {
    font-size: 0.85em;
  }
  .sub-text {
    font-size: 1em;
  }
  .left-arrow-back-to-home {
    display: none;
  }
  .footer-left-arrow {
    font-size: 0.9em;
  }
  .login-left-arrow-icon {
    font-size: 25px;
    top: -3px;
  }
  .logoImg {
    display: none;
  }

  
.lgntxt{
  display: none;
  
  }


}

@media (min-width: 576px) and (max-width: 767.98px) {
  .main {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    /* margin:2%; */
  }
  .left-side {
    margin: 0 auto;
    max-width: 443px;
    margin-top: 10px;
  }
  .right-side {
    margin: 0 auto;
    max-width: 443px;
  }
  .logoImg {
    display: none;
  }
  .left-side-text {
    font-size: 1.1em;
    margin-top: 10px;
    margin-bottom: 20px;
   
  }
  .login-text {
    font-size: 1.5em;
    
  }
  .sub-text {
    font-size: 1.05em;
  }
  .imageBackground {
    background-position: bottom 0px right 0px, 88% 11%;
    /* height:100%; */
  }
  .left-side,
  .right-side {
    width: 50%;
  }
  .left-arrow-back-to-home {
    display: none;
  }

.lgntxt{
display: none;

}

  /* .container{
    height:100vh
  } */
}
/* @media screen and (min-width: 576px) and (max-width: 767.98px) {
  .logoImg {
    height: 40px;
    width: 190px;
  }
  .left-side-text {
    font-size: 1em;
  }
  .imageBackground{
    background-position: bottom 0px right 0px, 58% 11%;

  }
  .login-text {
    font-size: 1.8em;
  }
  .main{
    margin:5%;
  }
} */
@media (min-width: 768px) and (max-width: 991.98px) {
  .logoImg {
    height: 50px;
    width: 250px;
  }
  .imageBackground {
    background-position: bottom 0px right 0px, 60% 11%;
    height: 100vh;
  }
  .main {
    margin: 10%;
  }
  .footer-left-arrow {
    display: none;
  }
  .container {
    height: 100vh;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .imageBackground {
    background-position: bottom 0px right 0px, 57% 11%;
    height: 100vh;
  }
  .main {
    margin: 7%;
  }
  .footer-left-arrow {
    display: none;
  }
}
@media (min-width: 1200px) and (max-width: 1367.98px) {
  .imageBackground {
    background-position: bottom 0px right 0px, 58% 11%;
    height: 100vh;
  }
  .main {
    margin: 10%;
  }
  .footer-left-arrow {
    display: none;
  }
}
@media (min-width: 1368px) and (max-width: 1919.98px) {
  .imageBackground {
    background-position: bottom 0px right 0px, 58% 11%;
    height: 100vh;
  }
  .main {
    margin: 10%;
  }
  .footer-left-arrow {
    display: none;
  }
}
@media (min-width: 1920px) {
  .imageBackground {
    background-position: bottom 0px right 0px, 58% 11%;
    height: 100vh;
  }
  .footer-left-arrow {
    display: none;
  }
  .main {
    margin: 10%;
  }
}
