.home-container {
  /* background-color: #f2f6fa;
  height:88vh;
  background-image: url("../../assets/images/map.png"); 
  
  background-position:right 134% bottom 50%;
  background-repeat: no-repeat;
  width:100%;
  position: relative; */
min-height: calc(100vh - 91px);
  /* background-color: #f2f6fa;
    height: 88vh;
    background-image: url("../../assets/images/map.png"); 
  
    background-position: calc(100% + 534px) calc(100% - -73px);
    background-repeat: no-repeat;
    width: 100%;
    position: relative;
   
    background-size: cover; */
  background-color: #f2f6fa;
  /* height: 88vh; */
  /* background-image: url("../../assets/images/map.png"); */
  /* background-position: -52% 52%; */
  background-position: 420px -110px;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;
  /* display: inline-block; */
  background-size: cover;
}

.wind-button {
  width: 100px;
  height: 40px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 15px 30px #b6b6b629;
  border-radius: 4px;
  opacity: 1;
  outline: none;
  border: none;
  color: #05192f;
  cursor: pointer;
  font-size: 1em;
  /* margin-right: 140px; */
  margin-top: 40px;
  font-family: "Source-SemiBold";
}
.wind-button:hover {
  background: #b6b6b650;
}
.wind-button-align {
  position: relative;
  right: 0;
  display: flex;
  justify-content: flex-end;
  z-index: 1;
}
.wind-icon {
  position: relative;
  top: 3px;
  right: 6px;
  width: 15px;
}
.trial-btn{
      color: #ffffff;
    background-color: #f76c6c;
    margin-right: 10px;
}
.trial-btn:hover{
  color: #000000;
}
.text {
  color: #05192f;
  font-size: 1.4em;
  font-family: "Source-SemiBold";
}
.map-img {
  /* background-image: url("../../assets/images/map.png");
       background-position: 0 -250px;  */
}
.map-img-align {
  width: 100%;
  /* height: 435px; */
  display: none;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
}
.map-dot-wrap{
  position: absolute;
  left: 40%;
  width: 1200px;
  height: 720px;
  top:50%;
  margin-top: -360px;
  background: url("../../assets/images/map.png") no-repeat left center;
  background-size: 1200px 720px;
}
.map-dots{
  position: relative;
}
.dot {
  position: absolute;
  cursor: pointer;
  border: 1px;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: inline-block;

      left: 143px;
    top: 316px;

  box-shadow: 0 0 0 0 rgba(247, 108, 108);
  transform: scale(1);
  animation: pulse 2s infinite;
}
.dot1 {
  position: absolute;
  cursor: pointer;
  border: 1px;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: inline-block;

  left: 163px;
  top: 343px; 

  box-shadow: 0 0 0 0 rgba(247, 108, 108);
  transform: scale(1);
  animation: pulse 2s infinite;
}
.dot2 {
  position: absolute;
  cursor: pointer;
  border: 1px;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: inline-block;

  left: 89px;
    top: 363px;

  box-shadow: 0 0 0 0 rgba(247, 108, 108);
  transform: scale(1);
  animation: pulse 2s infinite;
}
.dot3 {
  position: absolute;
  cursor: pointer;
  border: 1px;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: inline-block;

  left: 142px;
    top: 406px;

  box-shadow: 0 0 0 0 rgba(247, 108, 108);
  transform: scale(1);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(247, 108, 108);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.sea-img-card-wrap {
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 23%;
  justify-content: space-between;
}
.sea-img-wrap {
  width: 46%;
  height: 130px;
  margin-bottom: 25px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}
.sea-img-wrap:nth-child(3),
.sea-img-wrap:nth-child(4) {
  margin-bottom: 0px;
}
.sea-img-wrap.first-img {
  background-image: url("../../assets/images/northCalifornia.jpeg");
  cursor: pointer;
  box-shadow: 0px 15px 35px #1426688c;
  border-radius: 4px;
  opacity: 1;
}
.sea-img-wrap.first-img:hover {
  box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.41);
  -webkit-box-shadow: 3px 3px 1px #ccc;
  -moz-box-shadow: 3px 3px 1px #ccc;
}
.sea-img-wrap.second-img {
  background-image: url("../../assets/images/southernCalifornia.jpeg");
  cursor: pointer;
  box-shadow: 0px 15px 35px #1426688c;
  border-radius: 4px;

  opacity: 1;
}
.sea-img-wrap.second-img:hover {
  box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.41);
  -webkit-box-shadow: 3px 3px 1px #ccc;
  -moz-box-shadow: 3px 3px 1px #ccc;
}
.sea-img-wrap.third-img {
  background-image: url("../../assets/images/oahu.jpeg");
  cursor: pointer;
  box-shadow: 0px 15px 35px #1426688c;
  border-radius: 4px;
  opacity: 1;
  /* transition: transform .2s;  */
}
.sea-img-wrap.third-img:hover {
  box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.41);
  -webkit-box-shadow: 3px 3px 1px #ccc;
  -moz-box-shadow: 3px 3px 1px #ccc;
}
.sea-img-wrap.fourth-img {
  background-image: url("../../assets/images/hawaii.jpeg");
  cursor: pointer;
  box-shadow: 0px 15px 35px #1426688c;
  border-radius: 4px;
  opacity: 1;
  /* transition: transform .2s;  */
}
.sea-img-wrap.fourth-img:hover {
  box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.41);
  -webkit-box-shadow: 3px 3px 1px #ccc;
  -moz-box-shadow: 3px 3px 1px #ccc;
}
.sea-circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #f76c6c;
  align-items: center;
  justify-content: center;
  display: flex;
  color: #ffffff;
  font-family: "Source-SemiBold";
  font-size: 0.8em;
}
.sea-img {
  /* background: transparent
  linear-gradient(0deg, #1e2541 0%, #13182bb5 43%, #05070d00 100%) 0% 0%
  no-repeat padding-box; */
  display: flex;
  height: 100%;
  width: 100%;
  /* padding: 10px 10px 30px; */
  padding: 10px 10px 12px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  z-index: 9;
}
.sea-img p {
  color: white;
  z-index: 9;
  margin-bottom: 27px;
  font-family: "Source-SemiBold";
  font-size: 1em;
}
.sea-overlay {
  position: absolute;
  border-radius: 4px;
  /* top: 0; */
  bottom: 0;
  left: 0;
  right: 0;
  height: 40px;
  background: linear-gradient(360deg, #1e2541, #13182bb5, transparent);
  z-index: 1;
}
.home-text-card {
  margin-top: 20px;
  /* height: 160px; */
  width: 100%;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  display: flex;
  /* align-items: center; */
  /* flex-direction: column; */
  /* justify-content: center; */
  padding: 10px;
  margin-bottom: 20px;
}
.home-text-card-align {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  /* justify-content: center; */
  margin: 0 auto;
  /* text-align: center; */
}
.home-text-card-title {
  margin: 0;
  font-size: 1.2em;
  font-family: "Source-SemiBold";
  margin-bottom: 6px;
}
.home-text-card-subtext-title {
  margin: 0;
  font-size: 1.2em;
  font-family: "Source-Reg";
  margin-bottom: 6px;
}
p.home-text-card-subtext {
  margin: 0;
  font-size: 1em;
  font-family: "Source-Reg";
  margin-bottom: 4px;
}

.container-wrap {
  margin: 0 auto;
}
@media (max-width: 575.98px) {
  .sea-card-align {
    margin-left: 18px;
    margin-right: 18px;
  }
  .container-wrap {
    max-width: 100%;
  }
  .sea-img-wrap {
    width: 100%;
    /* margin-bottom: 10px; */
  }
  .sea-img-wrap:nth-child(3) {
    margin-bottom: 25px;
  }
  .sea-img-wrap:nth-child(4) {
    margin-bottom: 0px;
  }
  .sea-img-card-wrap {
    width: 100%;
  }

  .wind-button-align {
    right: 18px;
    top: 18px;
  }
  .wind-button {
    margin-top: 0px;
  }
  .home-container {
    background-image: none;
  }
  /* .home-text-card{
    margin-top: 4px;
    margin-bottom: 10px;
  } */
  .map-dot-wrap{
    position: relative;
    top: 0;
    margin: 0;
    left: 0;
    background-size: 900px 540px;
    height: 540px;
  }
  .dot {
    width: 10px;
    height: 10px;
    left: 111px;
    top: 242px;
  }
  .dot1 {
    width: 10px;
    height: 10px;
    left: 129px;
    top: 262px;
  }
  .dot2 {
    width: 10px;
    height: 10px;
    left: 71px;
    top: 278px;
  }
  .dot3 {
    width: 10px;
    height: 10px;
    left: 110px;
    top: 310px;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .container-wrap {
    max-width: 540px;
  }
  /* .home-container {
    height: 87.1vh;
  } */
  .sea-img-card-wrap {
    width: 50%;
  }
  .home-container {
    background-image: none;
  }
  .home-text-card {
    margin-top: 13px;
    margin-bottom: 16px;
  }
  /* .map-img-align{
    width: 137%;
  } */
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .container-wrap {
    max-width: 720px;
  }
  /* .home-container {
    height: 87.1vh;
  } */
  .sea-img-card-wrap {
    width: 38%;
  }
  .home-container {
    background-position: 403px 42px;
  }
  .map-dot-wrap{
    /* left: 403px; */
  }
  .home-text-card {
    margin-top: 25px;
    margin-bottom: 20px;
  }
  .map-img-align {
    display: none;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .container-wrap {
    max-width: 960px;
  }
  /* .home-container {
    height: 87vh;
  } */
  .sea-img-card-wrap {
    width: 38%;
  }
  .home-container {
    background-position: 488px -20px;
  }
  .map-dot-wrap{
    /* left: 488px; */
  }
  .home-text-card {
    margin-top: 25px;
    /* margin-bottom:25px; */
  }
  .map-img-align {
    display: none;
  }
}
@media (min-width: 1200px) and (max-width: 1367.98px) {
  .container-wrap {
    max-width: 1140px;
  }
  /* .home-container {
    height: 87.1vh;
  } */
  .sea-img-card-wrap {
    width: 38%;
  }
  .home-container {
    background-position: 529px -85px;
  }
  .map-dot-wrap{
    /* left: 529px; */
  }
  .home-text-card {
    margin-top: 25px;
    /* margin-bottom:20px; */
  }
  .map-img-align {
    display: none;
  }
}
@media (min-width: 1368px) and (max-width: 1919.98px) {
  .container-wrap {
    max-width: 1200px;
  }
  /* .home-container {
    height: 86.9vh;
  } */
  .sea-img-card-wrap {
    width: 38%;
  }
  .home-container {
    background-position: 766px -159px;
  }
  .map-dot-wrap{
    /* left: 766px; */
  }
  .home-text-card {
    margin-top: 25px;
    /* margin-bottom:25px; */
  }
  .map-img-align {
    display: none;
  }
  /* .dot2 {
    left: 129px;
    top: 356px;
  }
  .dot1 {
    left: 233px;
    top: 325px;
  }
  .dot {
    left: 205px;
    top: 288px;   
  }
  .dot3{
    left: 204px;
    top: 415px;
  } */
}
@media (min-width: 1920px) {
  .container-wrap {
    max-width: 1700px;
  }
  /* .home-container {
    height: 88.8vh;
  } */
  .sea-img-card-wrap {
    width: 38%;
  }
  .home-container {
    background-position: 771px -236px;
  }
  .map-dot-wrap{
    /* left: 771px; */
    }
  .map-img-align {
    display: none;
  }
  .dot {
    /* right: 889px;
    top: 277px; */
  }
}
