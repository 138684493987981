.StripeElement {
  /* margin: 15px auto; */
  margin-bottom: 20px;
  padding: 10px 12px;
  color: #32325d;
  background-color: white;
  box-shadow: rgb(136, 136, 136) 0px 0px;
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  width: 274px;
}
.StripeElement::placeholder {
  color: #cfd7df;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.product {
  width: 100%;
  max-width: 450px;
  margin: auto;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  overflow: hidden;
}

.btn-pay {
  display: block;
  width: 100%;
  border: none;
  background: linear-gradient(135deg, #f76c6c 0%, rgb(195, 40, 110) 100%);
  color: #fff;
  padding: 10px;
  font-size: 18px;
  cursor: pointer;
  font-family: Source-SemiBold;
  margin-bottom: 20px;
  width: 276px;
  /* font-weight: bold; */
}

.product-info {
  padding: 0 16px;
}

h3.product-title {
  font-size: 28px;
  margin-bottom: 15px;
}

h4.product-price {
  font-size: 24px;
  margin: 0;
  margin-bottom: 30px;
  color: #777;
  font-weight: 500;
}
/* .card-width {
  width: 274px;
} */
@media (max-width: 575.98px) {
  .StripeElement {
    width: 237px;
  }
  .btn-pay {
    width: 237px;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .StripeElement {
    width: 243px;
  }
  .btn-pay {
    width: 243px;
  }
  .card-width{
    width:241px;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .StripeElement {
    width: 243px;
  }
  .btn-pay {
    width: 244px;
  }
}
