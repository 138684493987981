.my-subscription-header {
  background-color: #f76c6c;
  height: 200px;
  width: 100%;
  /* display: flex; */
  /* align-items: center; */
  justify-content: center;

  /* margin-top: 40px; */
}
.my-subs-heading-align {
  /* margin-top: 35px; */
  display: flex;
  /* justify-content: center; */
  margin-left: 30px;
}
.my-subs-heading {
  font-size: 2.2em;
  font-family: "Source-Bold";
  color: white;
  margin: 0;
  margin-top: 35px;
}
.back-to-text {
  font-size: 1em;
  margin-top: 35px;
  /* margin:0; */
  color: white;
}
.card-align {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.my-subscription-card {
  width: 95%;
  background-color: white;
  border-radius: 1px;
  /* position: absolute;
  top: 215px; */
  /* box-shadow: rgb(204, 204, 204) 1px 5px 10px; */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  /* right: 51px; */
  /* right: 51px; */
  /* margin-left: 20px; */
  /* margin: 2%; */
  /* margin-right: 10px; */
  /* left: 20px; */
  position: relative;
  top: -81px;
}
.subscriptions-icon {
  width: 31px;
  height: 21px;
  position: relative;
  top: 0px;
}
.card-header {
  display: flex;
  flex-direction: row;
  /* justify-content: flex-end; */
  justify-content: space-between;
  padding: 40px;
}
.card-title {
  color: #000000;
  font-size: 1.1em;
  font-family: "Source-SemiBold";
}

.table-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* padding: 0px 10px 24px 41px; */
  padding: 20px;
  border-bottom: 1px solid #ddd;
}
.cancel {
  color: #f76c6c;
  cursor: pointer;
  font-size: 0.9em;
  font-family: "Source-Light";
}
.body-text {
  color: #000000;

  font-size: 0.9em;
  font-family: "Source-Light";
}
.title {
  font-size: 1em;
  color: #000000;
  font-family: Source-Reg;
  /* padding-right: 6px; */
}
#title {
  text-align: center;
  font-family: arial, sans-serif;
}

#students {
  text-align: center;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  border: none;
  width: 100%;
  margin-top: -12px;
}

#students td,
#students th {
  border: none;
  border-bottom: 1px solid #ddd;
  padding: 8px;
}
#students td {
  padding-bottom: 10px;
}

#students th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;

  font-size: 1em;
  color: #000000;
  font-weight: normal;
  font-family: Source-Reg;
}
.table-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* padding: 0px 10px 24px 41px; */
  margin-left: 12px;
  margin-right: 12px;
  border-bottom: 1px solid #ddd;
}
th,
td {
  padding: 0;
}

@media (max-width: 575.98px) {
  .title {
    font-size: 0.75em;
    padding-right: 6px;
  }
  #students th {
    font-size: 0.7em;
    padding-right: 4.5px;
  }
  .body-text {
    font-size: 0.7em;
  }
  .cancel {
    font-size: 0.7em;
  }
  .card-header {
    padding: 30px 0px 30px 15px;
  }
  .my-subs-heading-align {
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 15px;
  }
  .my-subs-heading {
    margin-top: 0px;
  }
}
