.subscription-container {
  background-color: rgb(242, 246, 250);
  /* height: 87vh; */
  /* display: flex; */
  /* align-items: center; */
}
.sub-main-container {
  margin: 0 auto;
}

.sub-main {
  justify-content: space-between;
  display: flex;
  flex: 1px 1;
  /* margin: 0px 110px 0px 140px; */
  /* margin: 1px 140px; */
  /* margin: 10%; */
  flex-direction: row;
  /* padding: 5% 0; */
  width: 100%;
}
.subscription-left-side,
.subscription-right-side {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* justify-content: center; */
  /* z-index: 9; */
}
.subscription-right-side {
  margin-left: auto;
  /* max-width: 459px; */
  /* max-width: 520px; */
  /* background-color: red; */
  width: 80%;
  max-width: 580px;
  padding-top: 50px;
}

.subscription-left-side {
  margin-right: auto;
  /* max-width: 20%; */
  /* background-color: green; */
  /* width: 20%; */
}
.mockup-wrap{
  margin-top: 50px;
}
.mockup-wrap img{
  width: 100%;
}
.subs-left-arrow-align {
  flex-direction: row;
  display: flex;
  /* margin-bottom: 40px; */
}
.subs-left-arrow {
  cursor: pointer;
  width: 20px;
  position: relative;
  top: 8px;
}
.subscription-top-text {
  margin: 0;
  margin-left: 10px;
  margin-top: 30px;
  font-size: 1em;
  font-family: Source-SemiBold;
  color: rgb(5, 25, 47);
}
.subs-left-title {
  font-size: 1.5em;
  font-family: Source-SemiBold;
  color: rgb(5, 25, 47);
  margin-bottom: 10px;
}
.subs-left-subtext {
  font-size: 1.2em;
  color: rgb(0, 0, 0);
  margin: 0px;
}
.subs-card {
  background-color: white;
  border-radius: 8px;
  box-shadow: rgb(204, 204, 204) 5px 5px 10px;
  /* padding: 20px; */
  width: 100%;
  margin-top: 15px;
  margin-bottom: 20px;
}
.subs-card-align {
  display: flex;
  /* flex-direction: row; */
  flex-direction: column;
  /* justify-content: space-between; */
  padding: 20px;
}
.cards {
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
}
.cards-wrap {
  width: 31%;
  /* width: 45%; */
  /* height: 130px; */
  margin-bottom: 25px;
}
.first-card {
  box-shadow: rgb(136, 136, 136) 0px 0px 1px;
  background-color: rgb(63, 77, 133);
  /* max-width: 250px; */
  /* padding: 30px; */
  padding: 1px 38px;
  border-radius: 8px;
  height: 250px;
  display: flex;
  margin-top: 15px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.subs-card-left-Align {
  padding-right: 0px;
  padding-left: 0px;
}
.subs-right-text {
  font-size: 1.2em;
  font-family: Source-SemiBold;
  color: rgb(5, 25, 47);
  /* margin: 20px 0px 25px; */
  margin: 0px;
  margin-bottom: 15px;
}
.radio-text-align {
  flex-direction: row;
  margin-bottom: 10px;
  display: flex;
  position: relative;
}
.radio-icon {
  color: rgb(247, 108, 108);
  position: absolute;
  left: 10px;
}
.subs-right-subText {
  margin: 0px 0px 0px 40px;
}
.subs-borderline {
  margin-left: 5px;
  margin-right: 5px;
  border-bottom: 0.5px solid #eee;
}
.round-ci {
  height: 25px;
  width: 25px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}
.subcribe-box-card {
  box-shadow: rgb(136, 136, 136) 0px 0px 1px;
  background-color: rgb(63, 77, 133);
  max-width: 250px;
  /* padding: 30px; */
  padding: 1px 38px;
  border-radius: 8px;

  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.subs-plan-title {
  font-size: 1em;
  color: white;
  font-family: Source-SemiBold;
  margin: 0px;
  text-align: center;
}
.subs-plan-subtext {
  margin: 0px;
  text-align: center;
  color: rgba(255, 255, 255, 0.822);
  font-family: Source-Reg;
  font-size: 1.1em;
}
.subscription-btn-align {
  position: absolute;
  bottom: 43px;
}
.subscribe-btn {
  border: none;
  outline: none;
  cursor: pointer;
  color: #ffffff;
  background-color: #f76c6c;
  font-family: "Source-SemiBold";
  width: 100%;
  padding: 9px 35px;
  text-align: center;
  border-radius: 5px;
}

@media (max-width: 575.98px) {
  .sub-main-container {
    max-width: 100%;
  }
  .subscription-left-side {
    margin: 0 auto;
    width: 80%;
  }
  .subscription-right-side {
    margin: 0 auto;
  }
  .sub-main {
    flex-direction: column;
    /* margin-left: 6%; */
  }
  .subs-left-arrow {
    top: 14px;
  }
  /* .subscription-container{
    height:100vh;
  } */
  .subscription-btn-align {
    position: relative;
    bottom: -27px;
  }
  .cards-wrap {
    width: 100%;
    /* height: 130px; */
    margin-bottom: 25px;
  }
  .subs-plan-subtext {
    font-size: 1.6em;
  }
  .subscribe-btn {
    padding: 10px 45px;
  }
  .first-card {
    height: 240px;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  /* .sub-main-container {
    max-width: 540px;
  } */

  .subscription-left-side {
    margin: 0 auto;
    width: 80%;
  }
  .subscription-right-side {
    margin: 0 auto;
  }
  .sub-main {
    flex-direction: column;
    /* margin-left: 6%; */
    /* margin-left: 3%; */
  }
  .subs-left-arrow {
    top: 14px;
  }
  /* .subscription-container{
    height:100vh;
  } */
  .subscription-btn-align {
    position: relative;
    bottom: -27px;
  }
  .cards-wrap {
    width: 100%;
    /* height: 130px; */
    margin-bottom: 25px;
  }
  .subs-card-left-Align {
    padding-left: 0px;
    padding-right: 0px;
  }
  .subs-plan-subtext {
    font-size: 1.7em;
  }
  .subs-plan-title {
    font-size: 1.4em;
  }
  .subscribe-btn {
    padding: 10px 45px;
  }
  .first-card {
    height: 240px;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .sub-main-container {
    max-width: 720px;
  }
  .subscription-left-side {
    width: 25%;
  }
  .subscription-right-side {
    width: 75%;
  }

  .subs-left-arrow {
    top: 14px;
  }

  .subs-plan-subtext {
    font-size: 1.2em;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .sub-main-container {
    max-width: 960px;
  }
  .subscription-left-side {
    width: 40%;
  }
  .subscription-right-side {
    width: 75%;
  }

  .subs-left-arrow {
    top: 14px;
  }
}
@media (min-width: 1200px) and (max-width: 1367.98px) {
  .sub-main-container {
    max-width: 1140px;
  }
  .subscription-left-side {
    width: 45%;
  }
  .subscription-right-side {
    width: 75%;
  }

  .subs-left-arrow {
    top: 14px;
  }
}
@media (min-width: 1368px) and (max-width: 1919.98px) {
  .sub-main-container {
    max-width: 1200px;
  }
  .subscription-left-side {
    width: 45%;
  }
  /* .subscription-container {
    height: 87vh;
  } */
  .subs-left-arrow {
    top: 14px;
  }
}
@media (min-width: 1920px) {
  .sub-main-container {
    max-width: 1700px;
  }
  .subscription-left-side {
    width: 40%;
  }
  /* .subscription-container {
    height: 87vh;
  } */
  .subs-left-arrow {
    top: 14px;
  }
}
